import React, { useContext } from 'react';
import { HashRouter, Switch, Route } from 'react-router-dom';

import history from '../history';
import Home from './home';
import Reports from './reports';
import Users from './users';
import Settings from './settings';
import Facilities from './Facility';
import Schedules from './schedules';
import NotFound from './notfound';
import UserRoles from '../components/screens/settings/RolesPermissions';
import ProfileSettings from './Profile';
import Logs from './logs';
import Lookup from './lookup';
import ZoneLookup from '../components/screens/settings/Lookup/ZoneLookup/Zone';
import StateLookup from '../components/screens/settings/Lookup/StateLookup/State';
import LGALookup from '../components/screens/settings/Lookup/LGALookup/lga';
import SectorLookup from '../components/screens/settings/Lookup/SectorLookup/index';
import Configurations from './configurations';
import CategoryAccess from '../access/CategoryAcess';
import { AppContext } from '../store/AppContext';
import VerifyPayment from './../components/screens/VerifyPayments/VerifyPayment';
import PaymentScreen from '../components/screens/payment';
import Test from './test';
import Analytics from './analytics';
import Reporting from './reporting';
import Templates from './templates';
import CreateFacilityScreen from '../components/screens/facilities2/CreateFacilityScreen';
import Facility from './Facility';
import FacilityCreate from './FacilityCreate';
import FacilityUpdate from './FacilityUpdate';

export default (props) => {
  const { state } = useContext(AppContext);
  const userCategory = state.auth.result.data.userCategory;
  const permissions = state.auth.result.data.userPermission;
  // const permissionsToStringArray = permissions.map((i) => {
  //   return i.permissionActivity.map((i2) => {
  //     return i2.privilegeCode;
  //   });
  // });
  // console.log(permissionsToStringArray);

  const userPermissions = permissions.flatMap((i) => {
    return i.permissionActivity.map((i2) => i2.privilegeCode.toLowerCase());
  });

  const canViewReports =
    userPermissions.includes('view_report') || userPermissions.includes('view_comparative_report');

  return (
    <HashRouter history={history}>
      <Switch>
        <CategoryAccess exact path="/test" component={Test} />
        <CategoryAccess exact path="/checkout" component={VerifyPayment} />
        <CategoryAccess exact path="/payment" component={PaymentScreen} />
        <CategoryAccess
          userCategory={userCategory}
          /*canView={canViewReports}*/ exact
          path="/users"
          component={Users}
        />
        <CategoryAccess
          userCategory={userCategory}
          path="/facilities"
          exact={true}
          component={Facility}
        />
        <CategoryAccess
          userCategory={userCategory}
          exact={true}
          path="/facilities/create"
          component={FacilityCreate}
        />
        <CategoryAccess
          userCategory={userCategory}
          exact={true}
          path="/facilities/edit/:facilityId"
          component={FacilityUpdate}
        />
        <CategoryAccess userCategory={userCategory} exact path="/reports" component={Reports} />
        <CategoryAccess userCategory={userCategory} exact path="/reporting" component={Reporting} />
        <CategoryAccess userCategory={userCategory} exact path="/logs" component={Logs} />
        <CategoryAccess userCategory={userCategory} exact path="/analytics" component={Analytics} />
        <CategoryAccess userCategory={userCategory} exact path="/schedules" component={Schedules} />
        <CategoryAccess userCategory={userCategory} exact path="/settings" component={Settings} />
        <CategoryAccess userCategory={userCategory} exact path="/" component={Home} />
        <CategoryAccess
          userCategory={userCategory}
          exact
          path="/settings/roles"
          component={UserRoles}
        />
        <CategoryAccess
          userCategory={userCategory}
          exact
          path="/settings/profile"
          component={ProfileSettings}
        />
        <CategoryAccess
          userCategory={userCategory}
          exact
          path="/settings/lookups"
          component={Lookup}
        />
        <CategoryAccess
          userCategory={userCategory}
          exact
          path="/lookup/settings/zone"
          component={ZoneLookup}
        />
        <CategoryAccess
          userCategory={userCategory}
          exact
          path="/lookup/settings/state"
          component={StateLookup}
        />
        <CategoryAccess
          userCategory={userCategory}
          exact
          path="/lookup/settings/lga"
          component={LGALookup}
        />
        <CategoryAccess
          userCategory={userCategory}
          exact
          path="/lookup/settings/sector"
          component={SectorLookup}
        />
        <CategoryAccess
          userCategory={userCategory}
          exact
          path="/settings/configurations"
          component={Configurations}
        />
        <CategoryAccess
          userCategory={userCategory}
          exact
          path="/settings/templates"
          component={Templates}
        />
        <CategoryAccess userCategory={userCategory} path="/" component={NotFound} />
      </Switch>
    </HashRouter>
  );
};
