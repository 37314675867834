// import React, { useState, useContext } from 'react';
// import styled from 'styled-components';
// import { AppContext } from '../../store/AppContext';
// import SideNav from './SideNav';
// import Header from './DashboardHeader';

// const LayoutItem = ({ className, children, isOpen, setOpen, headerTitle }) => {
//   return (
//     <div className={className}>
//       <SideNav isOpen={isOpen} setOpen={setOpen} />
//       <div className="main-panel">
//         <Header screenName={headerTitle} />
//         <div className="Main-Content">{children}</div>
//       </div>
//     </div>
//   );
// };

// const Layout = ({ className, children, headerTitle }) => {
//   const { state, dispatch } = useContext(AppContext);
//   const isOpen = state.sidebarOpen;

//   const setOpen = () => dispatch({ type: 'TOGGLE-SIDEBAR' });

//   return (
//     <_LayoutItem
//       className={className}
//       isOpen={isOpen}
//       setOpen={setOpen}
//       headerTitle={headerTitle}
//       children={children}
//     />
//   );
// };

// const generateWidth = (isOpen) => {
//   // if (isOpen) return `width: calc(100% - 204px) !important;`;
//   // else return `width: calc(100% - 64px) !important; `;
//   if (isOpen) return `width: calc(100% - var(--sidebar-main-w)) !important;`;
//   else return `width: calc(100% - var(--sidebar-min-w)) !important; `;
// };

// const _LayoutItem = styled(LayoutItem)`
//   display: flex;
//   .bread-crumb-container {
//     padding: 0 2rem;
//   }
//   .main-panel {
//     ${(props) => generateWidth(props.isOpen)};
//     position: absolute;
//     right: 0;
//     height: auto;
//     @media screen and (max-width: 769px) {
//       position: absolute;
//       right: 0;
//       width: calc(100% - 64px);
//       z-index: 10;
//     }
//   }
//   .Main-Content {
//     overflow: hidden;
//   }
// `;

// export default Layout;

import React, { useContext } from 'react';
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import UserContextProvider from '../../store/UserContext';
import { AppContext } from '../../store/AppContext';
import { baseURL, liveURL } from '../../helpers/api';
import NavUser from './NavUser';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import {
  Apartment,
  Assessment,
  CalendarMonth,
  Dashboard,
  People,
  PieChart,
  Settings,
} from '@mui/icons-material';
import logo from '../../assets/img/logo.png';

const Layout = withRouter(({ history, children, headerTitle }) => {
  const { state } = useContext(AppContext);
  const { userCategory, userPermission } = state.auth.result.data;
  const permissions = userPermission.flatMap((i) => {
    return i.permissionActivity.map((i2) => i2.privilegeCode.toLowerCase());
  });

  const canViewReports =
    permissions.includes('view_report') || userPermissions.includes('view_comparative_report');

  const canViewSchedules = permissions.includes('schedule_quarter');
  const canViewMonitoring =
    permissions.includes('activity_reports') ||
    permissions.includes('monitoring_reports') ||
    permissions.includes('payment_reports');

  const canViewFacility =
    permissions.includes('view_facility') ||
    permissions.includes('create_facility') ||
    permissions.includes('update_facility');

  const canViewUsers = permissions.includes('view_users') || permissions.includes('user_invite');

  const links = [
    {
      icon: <Dashboard sx={{ color: '#ffffff' }} />,
      name: 'Dashboard',
      url: '/',
      visible: true,
    },
    {
      icon: <PieChart sx={{ color: '#ffffff' }} />,
      name: 'Results',
      url: '/reports',
      visible: canViewReports,
    },
    {
      icon: <CalendarMonth sx={{ color: '#ffffff' }} />,
      name: 'QA/QC Scheduler',
      url: '/schedules',
      visible: canViewSchedules && userCategory === 'EDM',
    },
    {
      icon: <Apartment sx={{ color: '#ffffff' }} />,
      name: 'Facilities',
      url: '/facilities',
      visible: canViewFacility,
    },
    {
      icon: <People sx={{ color: '#ffffff' }} />,
      name: 'Users',
      url: '/users',
      visible: canViewUsers && userCategory === 'EDM',
    },
    // {
    //   Icon: ReportsIcon,
    //   name: 'Logs',
    //   url: '/logs',
    //   visible: true,
    // },
    {
      icon: <Assessment sx={{ color: '#ffffff' }} />,
      name: 'Reports',
      url: '/reporting',
      visible: canViewMonitoring,
    },
    // {
    //   Icon: ReportsIcon,
    //   name: 'Analytics',
    //   url: '/analytics',
    //   visible: true,
    // },
    {
      icon: <Settings sx={{ color: '#ffffff' }} />,
      name: 'Settings',
      url: '/settings',
      visible: true,
    },
  ];

  const rolename = state?.auth?.result?.data?.roleName;
  const liveEnv = baseURL === liveURL;
  const drawerWidth = 240;

  return (
    <UserContextProvider>
      <CssBaseline />
      <AppBar
        elevation={0}
        position="fixed"
        sx={{
          backgroundColor: '#FFFFFF',
          height: '9vh',
        }}
      >
        <Toolbar
          sx={{
            marginLeft: `${drawerWidth}px`,
            border: '0px solid red',
            backgroundColor: 'transparent',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Box sx={{ flexGrow: 1, display: 'flex', gap: 2, alignItems: 'center' }}>
              <Typography
                variant="h6"
                color="textSecondary"
                sx={{ fontWeight: '700', textTransform: 'capitalize' }}
              >
                {headerTitle}
              </Typography>
              {!liveEnv && (
                <Box
                  sx={{
                    backgroundColor: 'error.main',
                    padding: '0.5rem 1rem',
                    borderRadius: '50px',
                  }}
                >
                  <Typography sx={{ color: '#FFFFFF', fontWeight: '700' }}>
                    Test Environment
                  </Typography>
                </Box>
              )}
            </Box>
            <Box sx={{}}>
              <NavUser />
            </Box>
          </Box>
        </Toolbar>
        <Divider />
      </AppBar>

      <Box
        sx={{
          marginLeft: `${drawerWidth}px`,
        }}
      >
        <Drawer
          open={true}
          variant="persistent"
          sx={{
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: `${drawerWidth}px`,
              backgroundColor: '#0c5bab',
            },
          }}
        >
          <Box sx={{ height: '8.8vh', padding: '1.5rem' }}>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <img src={logo} alt="iems logo" style={{ height: '30px', width: '30px' }} />
              <Typography sx={{ color: '#FFFFFF' }}>
                <strong>I.E.M.S</strong>
              </Typography>
            </Box>
          </Box>
          <Divider />
          <List>
            {links?.map((link, index) => {
              if (link?.visible) {
                return (
                  <ListItemButton key={index}>
                    <ListItemIcon>{link?.icon}</ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography sx={{ color: '#FFFFFF' }}>
                          <Link to={link?.url}>{link?.name}</Link>
                        </Typography>
                      }
                    />
                  </ListItemButton>
                );
              }
            })}
          </List>
        </Drawer>

        <br />
        <br />
        <br />
        {children}
      </Box>
    </UserContextProvider>
  );
});

export default Layout;
