import { Close } from '@mui/icons-material';
import { Box, Dialog, IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import StandardResultQuarters from './StandardResultQuarters';
import StandardResultMeasurement from './StandardResultMeasurement';

export default function StandardResult({
  open,
  onClose,
  record,
  userCategory,
  userPermission,
}) {
  const [showQuarters, setShowQuarters] = useState(true);
  const [report, setReport] = useState();

  const handleRendering = () => {
    if (showQuarters) {
      return (
        <StandardResultQuarters
          record={record}
          userCategory={userCategory}
          userPermission={userPermission}
          onSelectReport={(show, report) => {
            setShowQuarters(show);
            setReport(report);
          }}
        />
      );
    } else {
      return <StandardResultMeasurement report={report} />;
    }
  };

  return (
    <Box>
      <Dialog open={open} fullWidth maxWidth="lg" fullScreen={false}>
        <Box display="flex" alignItems={'center'}>
          <Typography flexGrow={1}></Typography>
          <Box padding="1rem">
            <IconButton onClick={() => onClose()}>
              <Close />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ padding: '1rem' }}>{handleRendering()}</Box>
      </Dialog>
    </Box>
  );
}
