import React, { useState, useEffect } from 'react';
import { Popconfirm, Collapse, message, Empty, Divider } from 'antd';
import Table from '../../../../components/common/Table';
import Button from '../../../../components/common/Button';
import { getMeasurements, removeMeasurementParameters } from '../../../../helpers/api';
import { PlusOutlined, CaretRightOutlined } from '@ant-design/icons';
import { parseString3, parseString4 } from '../../../../helpers/utils';
import AddMeasurementModal from './AddMeasurement';
import AddParameterModal from './AddParameter';
import EditMeasurementModal from './EditMeasurement';

import '../style.css';
import EditParameter from './EditParameter';
import { Box, CircularProgress, IconButton, Stack, Tooltip } from '@mui/material';
import { DeleteOutline, Edit, EditNote } from '@mui/icons-material';
import LoaderSkeleton from '../../../common/LoaderSkeleton';

const { Panel } = Collapse;

const MeasurmentConfig = () => {
  const [addModal, setAddModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [tabKey, setTabKey] = useState('');
  const [openParameterModal, setOpenParameterModal] = useState(false);
  const [selectedMeasurementId, setSelectedMeasurementId] = useState('');
  const [openEditMeasurement, setOpenEditMeasurement] = useState(false);
  const [selectedMeasurementName, setSelectedMeasurementName] = useState('');
  const [editParameterModal, setEditParameterModal] = useState(false);
  const [selectedParameter, setSelectedParameter] = useState(null);

  const [measurementData, setMeasurementData] = useState([]);
  const [measurementLoading, setMeasurementLoading] = useState(false);
  const [parameterData, setParameterData] = useState([]);
  const [parameterLoading, setParameterLoading] = useState(false);

  useEffect(() => {
    if (reload) {
      fetchMeasurementData();
    }
  }, [reload]);

  useEffect(() => {
    fetchMeasurementData();
  }, []);

  const fetchMeasurementData = () => {
    setMeasurementLoading(true);
    getMeasurements()
      .then((res) => {
        const responseData = res.result.data;
        const initParameterData = responseData[0]?.parameters;
        const initActiveTab = responseData[0]?.measurementId;

        setMeasurementData(responseData);
        setTabKey(initActiveTab);
        setParameterData(initParameterData);
        setMeasurementLoading(false);
      })
      .catch((error) => {
        const errorMessage = error?.data?.result?.status?.desc;
        message.error(errorMessage);
        setMeasurementLoading(false);
      });
  };

  const handleContentRendering = () => {
    if (measurementLoading) {
      return (
        <div style={{ padding: '1rem' }}>
          <LoaderSkeleton />
          <br />
          <LoaderSkeleton />
          <br />
          <LoaderSkeleton />
        </div>
      );
    } else {
      if (measurementData?.length < 1) {
        return <Empty />;
      } else {
        return renderMeasurements();
      }
    }
  };

  // Edit PARAMETER
  const handleEditParameter = (parameterId, measurementId) => {
    const selected = parameterData?.find(
      (x) => x.parameterId === parameterId && x.measurementId === measurementId
    );

    if (selected) {
      setEditParameterModal(true);
      setSelectedParameter(selected);
    }
  };

  // Remove PARAMETER
  const handleRemoveParameter = (parameterId, measurementId) => {
    setParameterLoading(true);
    removeMeasurementParameters(measurementId, parameterId)
      .then((res) => {
        message.success('Parameter removed successfully');
        setParameterLoading(false);
        reloadData();
      })
      .catch((error) => {
        const errorMessage = error?.data?.result?.status?.desc;
        message.error(errorMessage ?? 'Something went wrong');
        setParameterLoading(false);
      });
  };

  // GET KEY OF ACTIVE ACCORDION TAB
  const handleTabView = (key) => {
    setTabKey(key);
    const filteredParameters = measurementData?.find((x) => x.measurementId === key)?.parameters;
    setParameterData(filteredParameters);
  };

  const renderParameters = () => {
    const columns = [
      {
        title: 'Parameter Name',
        dataIndex: 'unit',
        key: 'unit',
        render: (unit) => {
          if (unit) {
            return <span>{parseString4(parseString3(unit))}</span>;
          }
        },
      },

      {
        title: 'SI Unit',
        dataIndex: 'siUnit',
        key: 'siUnit',
        render: (siUnit) => {
          if (siUnit) {
            return <span>{parseString4(parseString3(siUnit))}</span>;
          }
        },
      },
      {
        title: 'NESREA Limit',
        dataIndex: 'nesrea',
        key: 'nesrea',
        render: (_, data) => {
          let symbol;

          if (data.nesreaDataType === 'LESS_THAN') symbol = '<';
          else if (data.nesreaDataType === 'GREATER_THAN') symbol = '>';
          else symbol = '';

          return <div>{!data.nesrea ? '-' : `${symbol} ${data.nesrea}`}</div>;
        },
      },
      {
        title: 'World Bank Limit',
        key: 'world',
        dataIndex: 'world',
        render: (_, data) => {
          return <div>{!data.world ? '-' : data.world}</div>;
        },
      },
      {
        title: '',
        dataIndex: 'parameterId',
        key: 'parameterId',
        render: (_, data) => {
          const parameterId = data?.parameterId;
          const measurementId = data?.measurementId;
          return (
            <Stack direction={'row'} alignItems={'center'}>
              <Tooltip title="Edit parameter">
                <IconButton
                  size="small"
                  color="info"
                  onClick={() => handleEditParameter(parameterId, measurementId)}
                >
                  <Edit fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Remove parameter">
                <IconButton size="small" color="error">
                  <Popconfirm
                    title="Are you sure remove this parameter?"
                    onConfirm={() => handleRemoveParameter(parameterId, measurementId)}
                    okText="Yes"
                    cancelText="No"
                  >
                    {parameterLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <DeleteOutline fontSize="small" />
                    )}
                  </Popconfirm>
                </IconButton>
              </Tooltip>
            </Stack>
          );
        },
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={parameterData}
        className="measurement__table"
        paddedTable
        rowClassName="measurements__row"
      />
    );
  };

  const handleEditMeasurement = (measurementId) => {
    const filteredData = measurementData.find((x) => x.measurementId === measurementId);
    if (filteredData) {
      setSelectedMeasurementName(filteredData?.measurementName);
      setSelectedMeasurementId(measurementId);
      setOpenEditMeasurement(true);
    }
  };

  // ADD PARAMETERS TO MEASUREMENT TYPE
  const handleAddParameter = (ev) => {
    setSelectedMeasurementId(ev.target.id);
    setOpenParameterModal(true);
  };

  // DISPLAY MEASUREMENTS
  const renderMeasurements = () => {
    return (
      <Box>
        <Collapse
          activeKey={tabKey}
          onChange={handleTabView}
          bordered={false}
          className="custom__collapse"
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          accordion
        >
          {measurementData?.map((item) => (
            <Panel
              key={item?.measurementId}
              header={item?.measurementName}
              className="custom__collapse__panel"
              extra={
                <Tooltip title="Edit Measurement">
                  <IconButton onClick={() => handleEditMeasurement(item?.measurementId)}>
                    <EditNote color="info" />
                  </IconButton>
                </Tooltip>
              }
            >
              <Box>
                <Box>{renderParameters()}</Box>
                <Button
                  type="primary"
                  appearance="default"
                  className="add__params__btn"
                  id={item?.measurementId}
                  onClick={handleAddParameter}
                >
                  Add Parameter
                </Button>
              </Box>
            </Panel>
          ))}
        </Collapse>
      </Box>
    );
  };

  const reloadData = () => {
    setReload(true);
  };

  return (
    <div className="measurement__container">
      <Box
        sx={{
          margin: { lg: '0 5rem', md: '0 5rem', sm: '1rem', xs: '1rem' },
        }}
      >
        <Box
          sx={{ display: 'flex', alignItems: 'center', border: '0px solid gray', width: '100%' }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <h3 className="heading" style={{ alignSelf: 'flex-start' }}>
              Measurement Types
            </h3>
          </Box>
          <Box>
            <Button
              appearance="default"
              type="primary"
              className="add__button"
              onClick={() => setAddModal(true)}
            >
              <PlusOutlined />
              New Measurement Type
            </Button>
          </Box>
        </Box>
        <Divider />
        {handleContentRendering()}
      </Box>

      {addModal && (
        <AddMeasurementModal
          open={addModal}
          close={() => setAddModal(false)}
          reloadMeasurements={reloadData}
        />
      )}

      {openParameterModal && (
        <AddParameterModal
          open={openParameterModal}
          close={() => setOpenParameterModal(false)}
          reloadMeasurements={reloadData}
          id={selectedMeasurementId}
        />
      )}

      {openEditMeasurement && (
        <EditMeasurementModal
          open={openEditMeasurement}
          close={() => setOpenEditMeasurement(false)}
          reloadMeasurements={reloadData}
          id={selectedMeasurementId}
          name={selectedMeasurementName}
        />
      )}
      {editParameterModal && (
        <EditParameter
          open={editParameterModal}
          close={() => setEditParameterModal(false)}
          data={selectedParameter}
          reloadMeasurements={reloadData}
        />
      )}
    </div>
  );
};

export default MeasurmentConfig;
