import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Uses localStorage as default storage
import { useDispatch, useSelector } from 'react-redux';
import testSlice from './slices/testSlice';
import facilityCreateSlice from './slices/facilityCreateSlice';
import facilityUpdateSlice from './slices/facilityUpdateSlice';

// Persist configuration
const persistConfig = {
  key: 'root', // Key to store in storage
  storage,
  whitelist: ['facilityCreateReducer'],
  blacklist: ['testReducer'],
};

const reducers = combineReducers({
  testReducer: testSlice,
  facilityCreateReducer: facilityCreateSlice,
  facilityUpdateReducer: facilityUpdateSlice,
});

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, reducers);

// Configure store with persisted reducer
const store = configureStore({
  reducer: persistedReducer, // Add persisted reducer
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          'persist/PERSIST',
          'persist/REHYDRATE',
          'persist/PAUSE',
          'persist/PURGE',
          'persist/FLUSH',
          'persist/REGISTER',
        ],
      },
    }),
});

// Persistor for persisting the store
export const persistor = persistStore(store);

// Custom hooks for dispatch and selector
export const useAppSelector = useSelector;
export const useAppDispatch = () => useDispatch();

export default store;
