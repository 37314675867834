import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  Grid2,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { getConsultants } from '../../../../helpers/api';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { openNotification } from '../../../../helpers/notification';
import MUIDataTable from 'mui-datatables';
import { Add, Delete } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../../../redux/reduxstore';
import { onStoreCreateFacilityDetails } from '../../../../redux/slices/facilityCreateSlice';

export default function CreateFacilityStep3({ onPrev, onFinalSubmit }) {
  const store = useAppSelector((state) => state.facilityCreateReducer);
  const dispatch = useAppDispatch();

  const nigDialCode = '+234';
  const [consultantData, setConsultantData] = useState([]);
  const [consultantLoading, setConsultantLoading] = useState(false);
  const [consultantSelected, setConsultantSelected] = useState();
  const [consultantList, setConsultantList] = useState([]);
  const [personelList, setPersonelList] = useState([]);
  const [canReceiveCert, setCanReceiveCert] = useState(false);
  const [canReceiveInvoice, setCanReceiveInvoice] = useState(false);

  const formik = useFormik({
    initialValues: {
      fullName: '',
      email: '',
      phone: '',
      designation: '',
      receiveInvoiceEmail: '',
      receiveCertEmail: '',
      facilityId: '',
      facilityName: '',
    },
    onSubmit: (values) => handleAddFacilityPersonels(values),
  });

  const getInitialFacilityPersonel = () => {
    setPersonelList(store?.facilityPersonel);
  };

  const getInitialConsultant = () => {
    const consultants = store?.consultantId?.map((item) => {
      const consultant = consultantData?.find((x) => x?.userId === item);
      if (consultant) {
        return {
          userId: consultant?.userId,
          fullName: consultant?.fullName,
          email: consultant?.email,
          status: consultant?.status,
        };
      }
    });

    if (consultants?.length) {
      setConsultantList(consultants);
    }
  };

  const handleAddToConsultantList = (values) => {
    const consultant = consultantData?.find((x) => x?.userId === values?.userId);
    if (consultant) {
      const exists = consultantList?.find((x) => x?.userId === values?.userId);
      if (exists) {
        return openNotification({
          type: 'error',
          title: 'Consultant has already been added',
        });
      } else {
        setConsultantList((prev) => [...prev, consultant]);
        return openNotification({
          type: 'success',
          title: 'Consultant was added successfully',
        });
      }
    }
  };

  const handleRemoveConsultant = (record) => {
    const index = consultantList.indexOf(record);
    const newConsultantList = [...consultantList];
    newConsultantList.splice(index, 1);
    setConsultantList(newConsultantList);
  };

  const handleAddFacilityPersonels = (values) => {
    let phoneNumber = values?.phone;
    let isValid = false;

    if (phoneNumber) {
      const length = phoneNumber?.length;
      const firstDigit = phoneNumber?.charAt(0);
      if (length === 11 && firstDigit === '0') {
        phoneNumber = `${nigDialCode?.slice(1)}${phoneNumber?.slice(1)}`;
        isValid = true;
      } else if (length === 10 && firstDigit !== 0) {
        phoneNumber = `${nigDialCode?.slice(1)}${phoneNumber}`;
        isValid = true;
      } else {
        return openNotification({
          type: 'error',
          title: 'Invalid phone number format',
        });
      }
    }

    if (isValid) {
      const payload = {
        fullName: values?.fullName,
        email: values?.email,
        phone: phoneNumber,
        designation: values?.designation,
        receiveInvoiceEmail: canReceiveInvoice ? 'true' : 'false',
        receiveCertEmail: canReceiveCert ? 'true' : 'false',
        facilityId: '',
        facilityName: '',
      };

      const exists = personelList?.find((x) => x?.email === payload?.email);
      if (exists) {
        return openNotification({
          type: 'error',
          title: 'Personnel already exists in the list',
        });
      } else {
        setPersonelList((prev) => [...prev, payload]);

        formik.resetForm();
        setCanReceiveCert(false);
        setCanReceiveInvoice(false);

        return openNotification({
          type: 'success',
          title: 'Personell was added successfully',
        });
      }
    }
  };

  const handleRemovePersonel = (record) => {
    const index = personelList.indexOf(record);
    const newPersonelList = [...personelList];
    newPersonelList.splice(index, 1);
    setPersonelList(newPersonelList);
  };

  const saveTempData = () => {
    const newConsultantIds = consultantList?.map((item) => {
      return item?.userId;
    });

    const reduxPayload = {
      //step 1
      facilityName: store?.facilityName,
      registrationNo: store?.registrationNo,
      address: store?.address,
      coordinates: store?.coordinates,
      facilityCategoryId: store?.facilityCategoryId,
      sectorId: store?.sectorId,
      zoneId: store?.zoneId,
      stateId: store?.stateId,
      lgaId: store?.lgaId,
      status: store?.status,
      productId: store?.productId,
      productName: store?.productName,
      rawMaterialId: store?.rawMaterialId,
      rawMaterialName: store?.rawMaterialName,
      wasteId: store?.wasteId,
      wasteName: store?.wasteName,
      facilityEia: store?.facilityEia,
      eiaCert: store?.store,
      remarks: store?.store,

      //step 2
      samplePoints: store?.samplePoints,

      //step 3
      consultantId: newConsultantIds,
      facilityPersonel: personelList,
    };

    dispatch(onStoreCreateFacilityDetails(reduxPayload));
  };

  const handleSubmit = () => {
    if (personelList?.length < 1) {
      return openNotification({
        type: 'error',
        title: 'You must enter at least one personnel',
      });
    } else {
      saveTempData();
      onFinalSubmit();
    }
  };

  const handlePrev = () => {
    saveTempData();
    onPrev(1);
  };

  const renderConsultantList = () => {
    const columns = [
      {
        name: '',
        label: 'Name',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            return <Typography variant="body2">{consultantList[index]?.fullName}</Typography>;
          },
        },
      },
      {
        name: 'email',
        label: 'Email',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            return <Typography variant="body2">{consultantList[index]?.email}</Typography>;
          },
        },
      },
      {
        name: 'status',
        label: 'Status',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            return (
              <Chip
                label={consultantList[index]?.status}
                variant="filled"
                color={consultantList[index]?.status === 'ACTIVE' ? 'success' : 'error'}
              />
            );
          },
        },
      },
      {
        name: '',
        label: 'Action',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const record = consultantList[index];
            return (
              <IconButton size="small" onClick={() => handleRemoveConsultant(record)}>
                <Delete fontSize="small" color="error" />
              </IconButton>
            );
          },
        },
      },
    ];

    return (
      <Paper
        sx={{
          boxShadow: `0px 1px 1px rgba(0, 0, 0, 0.1), 0px 2px 10px rgba(0, 0, 0, 0.15)`,
        }}
      >
        <MUIDataTable
          title=""
          data={consultantList}
          columns={columns}
          options={{
            elevation: 0,
            search: false,
            filter: false,
            download: false,
            print: false,
            viewColumns: false,
            selectableRows: 'none',
            responsive: 'standard',
            pagination: false,
            fixedHeader: true,
            tableBodyHeight: '200px',
            setRowProps: (ev, dataIndex) => {
              return {
                style: dataIndex % 2 === 0 ? { background: '#f5f7ff' } : { background: '#FFFFFFF' },
              };
            },
          }}
        />
      </Paper>
    );
  };

  const renderPersonelList = () => {
    const columns = [
      {
        name: '',
        label: 'Name',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const fullname = personelList[index]?.fullName;
            return <Typography variant="body2">{fullname}</Typography>;
          },
        },
      },
      {
        name: '',
        label: 'Contact',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const email = personelList[index]?.email;
            const phone = personelList[index]?.phone;
            return (
              <Box>
                <Typography variant="body2">{email}</Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{
                    fontSize: '12px',
                  }}
                >
                  {phone}
                </Typography>
              </Box>
            );
          },
        },
      },
      {
        name: 'designation',
        label: 'Designation',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const designation = personelList[index]?.designation;
            return <Typography variant="body2">{designation}</Typography>;
          },
        },
      },
      {
        name: '',
        label: 'Notification',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const certNotif = personelList[index]?.receiveCertEmail === 'true' ? 'Yes' : 'No';
            const invoiceNotif = personelList[index]?.receiveInvoiceEmail === 'true' ? 'Yes' : 'No';
            return (
              <Box>
                <Box>
                  <Typography
                    component={'span'}
                    variant="body2"
                    color="textSecondary"
                    sx={{ width: '80px', display: 'inline-block' }}
                  >
                    Certificate:
                  </Typography>
                  <Typography component={'span'} variant="body2">
                    {certNotif}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    component={'span'}
                    variant="body2"
                    color="textSecondary"
                    sx={{ width: '80px', display: 'inline-block' }}
                  >
                    Invoice:
                  </Typography>
                  <Typography component={'span'} variant="body2">
                    {invoiceNotif}
                  </Typography>
                </Box>
              </Box>
            );
          },
        },
      },
      {
        name: '',
        label: 'Action',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const record = personelList[index];
            return (
              <IconButton size="small" onClick={() => handleRemovePersonel(record)}>
                <Delete fontSize="small" color="error" />
              </IconButton>
            );
          },
        },
      },
    ];

    return (
      <Paper
        sx={{
          //overflowY: 'scroll',
          boxShadow: `0px 1px 1px rgba(0, 0, 0, 0.1), 0px 2px 10px rgba(0, 0, 0, 0.15)`,
        }}
      >
        <MUIDataTable
          title=""
          data={personelList}
          columns={columns}
          options={{
            elevation: 0,
            search: false,
            filter: false,
            download: false,
            print: false,
            viewColumns: false,
            selectableRows: 'none',
            responsive: 'standard',
            pagination: false,
            fixedHeader: true,
            tableBodyHeight: '200px',
            setRowProps: (ev, dataIndex) => {
              return {
                style: dataIndex % 2 === 0 ? { background: '#f5f7ff' } : { background: '#FFFFFFF' },
              };
            },
          }}
        />
      </Paper>
    );
  };

  const fetchConsultant = () => {
    setConsultantLoading(true);
    getConsultants()
      .then((response) => {
        const responseData = response?.result?.data;
        setConsultantData(responseData);
      })
      .catch((err) => {})
      .finally(() => setConsultantLoading(false));
  };

  useEffect(() => {
    getInitialFacilityPersonel();
  }, []);

  useEffect(() => {
    getInitialConsultant();
  }, [consultantData]);

  useEffect(() => {
    fetchConsultant();
  }, []);

  return (
    <Box>
      <Box>
        <Grid2 container spacing={2}>
          <Grid2 size={{ lg: 12, md: 12, sm: 12, xs: 12 }}>
            <Typography variant="body1" color="textPrimary">
              <strong>Consultants</strong>
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Select consultants that will manage the facility
            </Typography>
          </Grid2>
          <Grid2 size={{ lg: 12, md: 12, sm: 12, xs: 12 }}>
            <Autocomplete
              fullWidth
              disablePortal
              value={consultantSelected}
              options={consultantData}
              getOptionLabel={(option) => option.fullName}
              onChange={(e, value) => {
                if (value) {
                  handleAddToConsultantList(value);
                } else setConsultantSelected({ userId: '', fullName: '' });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Consultants"
                  sx={{ backgroundColor: '#FFFFFF' }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: consultantLoading && (
                      <CircularProgress size={20} color="primary" />
                    ),
                  }}
                />
              )}
            />
          </Grid2>
          <Grid2 size={{ lg: 12, md: 12, sm: 12, xs: 12 }}>{renderConsultantList()}</Grid2>
        </Grid2>
      </Box>
      <br />
      <br />
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <Grid2 container spacing={2}>
            <Grid2 size={{ lg: 12, md: 12, sm: 12, xs: 12 }}>
              <Typography variant="body1" color="textPrimary">
                <strong>Facility Personel</strong>
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Enter the information of the facility contact persons
              </Typography>
            </Grid2>
            <Grid2 size={{ lg: 6, md: 6, sm: 6, xs: 6 }}>
              <TextField
                name="fullName"
                label="Full Name"
                variant="outlined"
                value={formik.values.fullName}
                fullWidth
                onChange={formik.handleChange}
                required
                sx={{ backgroundColor: '#FFFFFF' }}
              />
            </Grid2>
            <Grid2 size={{ lg: 6, md: 6, sm: 6, xs: 6 }}>
              <TextField
                name="email"
                label="Email"
                variant="outlined"
                value={formik.values.email}
                fullWidth
                type="email"
                onChange={formik.handleChange}
                required
                sx={{ backgroundColor: '#FFFFFF' }}
              />
            </Grid2>
            <Grid2 size={{ lg: 6, md: 6, sm: 6, xs: 6 }}>
              <TextField
                name="phone"
                label="Phone No."
                variant="outlined"
                value={formik.values.phone}
                fullWidth
                onChange={formik.handleChange}
                //required
                sx={{ backgroundColor: '#FFFFFF' }}
                slotProps={{
                  input: {
                    startAdornment: <Typography>{nigDialCode}</Typography>,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ lg: 6, md: 6, sm: 6, xs: 6 }}>
              <TextField
                name="designation"
                label="Designation"
                variant="outlined"
                value={formik.values.designation}
                fullWidth
                onChange={formik.handleChange}
                required
                sx={{ backgroundColor: '#FFFFFF' }}
              />
            </Grid2>
            <Grid2 size={{ lg: 6, md: 6, sm: 6, xs: 6 }}>
              <FormControlLabel
                label="Can receive the certificate in their email"
                control={
                  <Checkbox
                    checked={canReceiveCert}
                    onChange={(e) => setCanReceiveCert(e.target.checked)}
                  />
                }
              />
            </Grid2>
            <Grid2 size={{ lg: 6, md: 6, sm: 6, xs: 6 }}>
              <FormControlLabel
                label="Can receive the invoice in their email"
                control={
                  <Checkbox
                    checked={canReceiveInvoice}
                    onChange={(e) => setCanReceiveInvoice(e.target.checked)}
                  />
                }
              />
            </Grid2>
            <Grid2 size={{ lg: 12, md: 12, sm: 12, xs: 12 }}>
              <Button
                type="submit"
                variant="contained"
                color="info"
                startIcon={<Add />}
                sx={{ borderRadius: '50px', textTransform: 'none', width: '100px' }}
              >
                Add
              </Button>
            </Grid2>
            <Grid2 size={{ lg: 12, md: 12, sm: 12, xs: 12 }}>
              <br />
              {renderPersonelList()}
            </Grid2>
            <Grid2 size={{ lg: 12, md: 12, sm: 12, xs: 12 }}>
              <br />
              <Box>
                <Button
                  type="button"
                  variant="outlined"
                  size="large"
                  onClick={handlePrev}
                  sx={{ marginRight: '10px', textTransform: 'none', width: '100px' }}
                >
                  Previous
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  size="large"
                  onClick={handleSubmit}
                  sx={{ textTransform: 'none', width: '100px' }}
                >
                  Submit
                </Button>
              </Box>
            </Grid2>
          </Grid2>
        </form>
      </Box>
    </Box>
  );
}
