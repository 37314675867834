import {
  Alert,
  Backdrop,
  Box,
  Breadcrumbs,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CreateFacilityStep1 from './create-facility/CreateFacilityStep1';
import CreateFacilityStep2 from './create-facility/CreateFacilityStep2';
import CreateFacilityStep3 from './create-facility/CreateFacilityStep3';
import { useAppDispatch, useAppSelector } from '../../../redux/reduxstore';
import { Add, CheckCircle, Close, Error, KeyboardReturn } from '@mui/icons-material';
import { createFacility } from '../../../helpers/api';
import { onClearCreateFacilityDetails } from '../../../redux/slices/facilityCreateSlice';
import { withRouter } from 'react-router';

const CreateFacilityScreen = withRouter(({ history }) => {
  const steps = [
    { index: 0, item: 'Facility information' },
    { index: 1, item: 'Sample Points' },
    { index: 2, item: 'Consultant & Personnel' },
  ];

  const store = useAppSelector((state) => state.facilityCreateReducer);
  const dispatch = useAppDispatch();
  const [step, setStep] = useState(0);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleCreateFacility = () => {
    setLoading(true);
    createFacility(store)
      .then((response) => {
        setSuccess(true);
      })
      .catch((err) => {
        const errorMsg = err?.data?.result?.status?.desc;
        setErrorMessage(errorMsg);
        setError(true);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (store?.facilityName) {
      setDialogOpen(true);
    }
  }, []);

  const renderSuccess = () => {
    return (
      <Box sx={{ textAlign: 'center', padding: '2rem' }}>
        <CheckCircle color="success" sx={{ fontSize: '7rem' }} />
        <Typography variant="h5">Successfull</Typography>
        <br />
        <Typography variant="body1" color="textSecondary">
          Return to facility list
        </Typography>
        <br />
        <Stack direction={'row'} spacing={2}>
          <Button
            variant="outlined"
            size="large"
            sx={{ textTransform: 'none' }}
            startIcon={<KeyboardReturn />}
            onClick={() => {
              dispatch(onClearCreateFacilityDetails());
              history.push('/facilities');
            }}
          >
            Return to Facilities
          </Button>
          <Button
            variant="contained"
            size="large"
            sx={{ textTransform: 'none' }}
            startIcon={<Add />}
            onClick={() => {
              setError(false);
              setSuccess(false);
              setShowBackdrop(false);
              dispatch(onClearCreateFacilityDetails());
              setStep(1);
            }}
          >
            Create another Facility
          </Button>
        </Stack>
      </Box>
    );
  };

  const renderLoading = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          alignItems: 'center',
          padding: '2rem',
          width: 'fit-content',
        }}
      >
        <CircularProgress size={30} />
        <Typography>Creating facility. Please wait. . .</Typography>
      </Box>
    );
  };

  const renderError = () => {
    return (
      <Box sx={{ textAlign: 'center', padding: '2rem' }}>
        <Error color="error" sx={{ fontSize: '7rem' }} />
        <Typography variant="h5">Failed</Typography>
        <br />
        <Typography variant="body1" color="textSecondary">
          {errorMessage}
        </Typography>
        <br />
        <Stack direction={'row'} spacing={2}>
          <Button
            variant="outlined"
            size="large"
            sx={{ textTransform: 'none' }}
            startIcon={<KeyboardReturn />}
            onClick={() => {
              dispatch(onClearCreateFacilityDetails());
              history.push('/facilities');
            }}
          >
            Return to Facilities
          </Button>
          <Button
            variant="outlined"
            size="large"
            sx={{ textTransform: 'none', width: '200px' }}
            startIcon={<Close />}
            onClick={() => {
              setError(false);
              setSuccess(false);
              setShowBackdrop(false);
            }}
          >
            Close
          </Button>
        </Stack>
      </Box>
    );
  };

  return (
    <Box sx={{ padding: '1rem', background: '#f9f9f9' }}>
      <Box padding="1rem" sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs sx={{ flexGrow: 1 }}>
          <Link to="/facilities">
            <Typography variant="body2" color="info.main">
              Facilities
            </Typography>
          </Link>
          <Typography variant="body2" color="text.primary">
            Create Facility
          </Typography>
        </Breadcrumbs>
        <Box sx={{ width: '50%' }}>
          <Stepper activeStep={step} nonLinear>
            {steps.map((item) => (
              <Step key={item.index}>
                <StepLabel>{item.item}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Box>
      <Box
        sx={{
          padding: '1rem',
          margin: { lg: '0 12rem', md: '0 12rem', sm: '0rem', xs: '0rem' },
        }}
      >
        {step === 0 && <CreateFacilityStep1 onNext={(stepValue) => setStep(stepValue)} />}
        {step === 1 && (
          <CreateFacilityStep2
            onNext={(stepValue) => setStep(stepValue)}
            onPrev={(stepValue) => setStep(stepValue)}
          />
        )}
        {step === 2 && (
          <CreateFacilityStep3
            onPrev={(stepValue) => setStep(stepValue)}
            onFinalSubmit={() => {
              handleCreateFacility();
              setShowBackdrop(true);
            }}
          />
        )}
      </Box>

      {showBackdrop && (
        <Backdrop open={showBackdrop} sx={{ zIndex: 10000 }}>
          <Box
            sx={{
              backgroundColor: 'white',
              width: '35%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {loading && renderLoading()}
            {success && renderSuccess()}
            {error && renderError()}
          </Box>
        </Backdrop>
      )}

      {dialogOpen && (
        <Dialog open={dialogOpen} fullWidth maxWidth="sm">
          {/* <Box display="flex" alignItems={'center'}>
            <Typography flexGrow={1}></Typography>
            <Box padding="0.5rem">
              <IconButton onClick={() => setDialogOpen(false)}>
                <Close />
              </IconButton>
            </Box>
          </Box> */}
          <DialogContent>
            <br />
            <Alert severity="info">
              Creation of <strong>{store?.facilityName}</strong> is in progress
            </Alert>
            <br />
            <Typography variant="body2">
              You didn't complete the facility creation process but we kept your data for you.
            </Typography>
            <br />
            <Typography variant="body2" color="error">
              Would you like to continue from were you stopped?
            </Typography>
          </DialogContent>
          <DialogActions sx={{ padding: '1rem' }}>
            <Button
              color="primary"
              onClick={() => {
                dispatch(onClearCreateFacilityDetails());
                setDialogOpen(false);
                window.location.reload();
              }}
              sx={{ textTransform: 'none', borderRadius: '30px' }}
            >
              No, create another one
            </Button>
            <Button
              label="Yes, I want to continue"
              variant="contained"
              onClick={() => setDialogOpen(false)}
              sx={{ textTransform: 'none', borderRadius: '30px' }}
            >
              Yes, I want to continue
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
});

export default CreateFacilityScreen;
