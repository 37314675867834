import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
  Row,
  Col,
  Form,
  Popconfirm,
  InputNumber,
  Radio,
  Modal,
  message,
  Switch,
  Divider,
  Segmented,
} from 'antd';
import {
  SettingFilled,
  EditOutlined,
  //DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { openNotification } from '../../helpers/notification';
import LocationInput from './LocationInput';
import Map from '../../components/Map/fnMap';
import Table from '../../components/common/Table';
import Input from '../../components/common/AntInput';
import Select from '../../components/common/Select';
import Button from '../../components/common/Button';
import LoadingContent from '../../components/common/LoadingContent';
import { properCase, isLatitude, isLongitude } from '../../helpers/utils';
import {
  getMeasurements,
  getIcons,
  getEquipments,
  getStandardSamplePoints,
} from '../../helpers/api';
import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import { Delete, DeleteOutline, Edit } from '@mui/icons-material';

// different from the one in utils
// cos latitude isn't compulsory in this step
const checkLatitude = (rule, value) => {
  let val;
  if (value) {
    if (typeof value === 'object') {
      if ('loc' in value && value.loc !== '') val = value.loc;
    } else {
      val = value;
    }
  } else return Promise.resolve();
  if (!val) return Promise.resolve();
  if (isLatitude(val)) {
    return Promise.resolve();
  }

  return Promise.reject('Latitude must be between -90 and 90 degrees!');
};

// different from the one in utils
// cos latitude isn't compulsory in this step
const checkLongitude = (rule, value) => {
  let val;
  if (value) {
    if (typeof value === 'object') {
      if ('loc' in value && value.loc !== '') val = value.loc;
    } else {
      val = value;
    }
  } else return Promise.resolve();
  if (!val) return Promise.resolve();

  if (isLongitude(val)) {
    return Promise.resolve();
  }
  return Promise.reject('Longitude must be between -180 and 180 degrees!');
};

function toDegreesMinutesAndSeconds(coordinate) {
  var absolute = Math.abs(coordinate);
  var degrees = Math.floor(absolute);
  var minutesNotTruncated = (absolute - degrees) * 60;
  var minutes = Math.floor(minutesNotTruncated);
  var seconds = Math.floor((minutesNotTruncated - minutes) * 60);

  return degrees + ' ' + minutes + ' ' + seconds;
}

function convertDMS(lat, lng) {
  var latitude = toDegreesMinutesAndSeconds(lat);
  var latitudeCardinal = lat >= 0 ? 'N' : 'S';

  var longitude = toDegreesMinutesAndSeconds(lng);
  var longitudeCardinal = lng >= 0 ? 'E' : 'W';

  return latitude + ' ' + latitudeCardinal + ' - ' + longitude + ' ' + longitudeCardinal;
}

const StepTwo = ({ className, state: initialFormState = {}, updateMasterForm, next, prev }) => {
  const { stepTwoState } = initialFormState;
  const {
    icon: initIcon = {},
    runs: initRuns = '1',
    samplePointName: sampName = '',
    latitude: lat = '',
    longitude: long = '',
    samplePoints: sampPoints = [],
    samplePointTypes: initSamplePoints = [],
    measureNoise: initMeasureNoise = 1,
    equpipments: initEquipments = [],
  } = stepTwoState;

  const [icon, setIcon] = useState(initIcon);
  const [runs] = useState(initRuns);
  const [showIconSet, setShowIconSet] = useState(false);
  const [iconSets, setIconSet] = useState('');
  const [showMap, setShowMap] = useState(false);
  const [latitude, setLatitude] = useState(lat);
  const [longitude, setLongitude] = useState(long);
  const [samplePoints, setSamplePoints] = useState(sampPoints);
  const [samplePointName] = useState(sampName);
  const [samplePointTypes, setSamplePointTypes] = useState(initSamplePoints);
  const [equipments, setEquipments] = useState(initEquipments);
  const [editing, setEditing] = useState(false);
  const [editingState, setEditingState] = useState('');
  const [measureNoise, setMeasureNoice] = useState(initMeasureNoise);
  const [load, reload] = useState(false);

  const [measurementOptions, setMeasurementOptions] = useState([]);
  const [equipmentOptions, setEquipmentOptions] = useState([]);
  const [selectedSamplePoint, setSelectedSamplePoint] = useState();
  const [toEdit, setToEdit] = useState(false);
  const formRef = useRef();

  const spTypes = ['Standard', 'Others'];
  const [selectedSpType, setSelectedSpType] = useState(spTypes[0]);
  const [standardSamplePointOptions, setStandardSamplePointOptions] = useState([]);
  const [standardSamplePointLoading, setStandardSamplePointLoading] = useState(false);

  useEffect(() => {
    getMeasurementOptions();
  }, []);

  useEffect(() => {
    getEquipmentOptions();
  }, []);

  useEffect(() => {
    getStandardSamplePointOptions();
  }, []);

  const getMeasurementOptions = () => {
    getMeasurements().then((response) => {
      const responseData = response?.result?.data;
      const options = responseData?.map((item) => {
        return {
          value: item.measurementId,
          label: item.measurementName,
        };
      });

      setMeasurementOptions(options);
    });
  };

  const getStandardSamplePointOptions = () => {
    getStandardSamplePoints()
      .then((res) => {
        const responseData = res?.result?.data;
        const options = responseData?.map((item) => {
          return {
            value: item?.standardSamplePointId,
            label: item?.standardSamplePointName,
          };
        });

        setStandardSamplePointOptions(options);
      })
      .catch((error) => {});
  };

  const getEquipmentOptions = () => {
    getEquipments().then((response) => {
      const responseData = response?.result?.data;
      const options = responseData?.map((item) => {
        return {
          value: item.equipmentId,
          label: item.equipmentName,
        };
      });
      options.unshift({ value: '', label: '' });
      setEquipmentOptions(options);
    });
  };

  const handleEditSamplePoint = (samplePointRecord, isToEdit) => {
    const coordinates = samplePointRecord?.actualCoordinates?.split(' - ');
    const longitude = coordinates[0];
    const latitude = coordinates[1];
    const noise = samplePointRecord?.measureNoise;

    formRef.current.setFieldsValue({ samplePointName: samplePointRecord?.samplePointName });
    formRef.current.setFieldsValue({ icon: samplePointRecord?.icon });
    formRef.current.setFieldsValue({ measurementId: samplePointRecord?.measurementId });
    formRef.current.setFieldsValue({ equipmentId: samplePointRecord?.equipmentId });
    formRef.current.setFieldsValue({ checkCount: samplePointRecord?.checkCount });
    formRef.current.setFieldsValue({ longitude: longitude });
    formRef.current.setFieldsValue({ latitude: latitude });
    setMeasureNoice(noise);

    const newSamplePoints = samplePoints.filter((x) => x.id !== samplePointRecord.id);
    setSamplePoints(newSamplePoints);
    setToEdit(isToEdit);
    setSelectedSamplePoint(samplePointRecord);
  };

  const addSamplePoint = (values) => {
    const equipmentName = equipmentOptions?.filter((x) => x.value === values?.equipmentId)[0].label;
    const measurementName = measurementOptions?.filter((x) => x.value === values?.measurementId)[0]
      .label;
    const actualCoordinates = `${values.latitude} - ${values.longitude}`;

    let spName = '';
    let spId = '';

    if (values?.samplePointName) {
      spName = values?.samplePointName;
      //spId = selectedSamplePoint?.id;
    } else {
      const standard = standardSamplePointOptions?.find(
        (x) => x.value === values?.standardSamplePointId
      );
      spName = standard?.label;
      spId = standard?.value;
    }

    const samplePointToAdd = {
      id: selectedSamplePoint?.id,
      key: selectedSamplePoint?.key,
      samplePointName: spName,
      checkCount: !values?.runs ? 1 : values?.runs,
      equipmentId: values.equipmentId,
      equipmentName: equipmentName,
      icon: values?.icon,
      measurementId: values.measurementId,
      measurementName: measurementName,
      samplePointType: values.measurementId,
      measureNoise: !measureNoise ? 0 : 1,
      actualCoordinates: actualCoordinates,
      standardSamplePointId: spId,
    };

    //console.log('samplePointToAdd', samplePointToAdd);
    setSamplePoints((prev) => [...prev, samplePointToAdd]);
  };

  const deleteSamplePoint = (recordKey) => {
    const newState = samplePoints.filter((samp) => samp.key !== recordKey);
    setSamplePoints(newState);
  };

  //
  //
  //

  //
  //
  //

  //
  //
  //

  //
  //
  //
  //
  //

  const columns = [
    {
      title: 'Icon',
      dataIndex: 'icon',
      render: (_, record) => (
        <Imgs
          src={record.icon.iconSrc ? record.icon.iconSrc : record.icon ? record.icon : ''}
          alt={record.icon.iconName ? record.icon.iconName : ''}
        />
      ),
    },
    {
      title: 'Sample Point',
      dataIndex: 'samplePointName',
      width: '23%',
      ellipsis: true,
    },
    {
      title: 'Type',
      dataIndex: 'measurementId',
      ellipsis: true,
      render: (_, record) => {
        return <div>{record?.measurementName}</div>;
      },
    },
    {
      title: 'Equipment',
      dataIndex: 'equipmentName',
      ellipsis: true,
    },
    // {
    //   title: 'Noise',
    //   dataIndex: 'measureNoise',
    //   ellipsis: true,
    //   render: (_, record) => {
    //     if (record?.measureNoise === 0) return 'No';
    //     else return 'Yes';
    //   },
    // },
    // {
    //   title: '# Runs',
    //   dataIndex: 'checkCount',
    //   width: '13%',
    //   ellipsis: true,
    //   render: (runs) => <span>{runs}</span>,
    // },
    {
      title: 'Co-ordinates',
      dataIndex: 'coordinates',
      render: (_, record) => {
        const split = record?.actualCoordinates?.split(' - ');
        const longitude = split[0];
        const latitude = split[1];
        return (
          <div>
            <div>{longitude}N</div>
            <div>{latitude}E</div>
          </div>
        );
      },
    },
    {
      title: '',
      key: 'action',
      width: '10%',
      render: (_, record) => {
        return (
          <Stack direction="row" alignItems="center">
            <Tooltip title="Edit">
              <IconButton size="small" onClick={() => handleEditSamplePoint(record, true)}>
                <Edit color="info" fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Remove">
              <IconButton
                size="small"
                onClick={() => {
                  deleteSamplePoint(record);
                }}
              >
                <DeleteOutline color="error" fontSize="small" />
              </IconButton>
            </Tooltip>
          </Stack>
        );
        // return (
        //   <div style={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        //     <DeleteOutlined className="icon-delete-row" />
        //     <EditOutlined
        //       className="icon-edit-row"
        //       onClick={() => handleEditSamplePoint(record, true)}
        //     />
        //   </div>
        // );
      },
    },
  ];

  const [form] = Form.useForm();

  // const formItemLayout = {
  //   // labelCol: { span: 4 },
  //   // wrapperCol: { span: 14 },
  // };

  // const tailLayout = {
  //   // wrapperCol: { offset: 0, span: 14 },
  // };

  const { Option } = Select;

  // function getSamplePointTypes() {
  //   getMeasurements()
  //     .then(({ result }) => {
  //       const mLists = [];
  //       result.data &&
  //         result.data.length > 0 &&
  //         result.data.forEach((res) =>
  //           mLists.push({
  //             measurementId: res.measurementId,
  //             measurementName: res.measurementName,
  //             measurementCode: res.measurementCode,
  //             measurementOrder: res.measurementOrder,
  //             name: res.measurementName,
  //             id: res.measurementId,
  //             text: res.measurementName,
  //           })
  //         );
  //       setSamplePointTypes(mLists);
  //     })
  //     .catch((err) => {
  //       return openNotification({
  //         type: 'error',
  //         title: 'Failed to get sample point types',
  //         message: `We encountered an error while trying to get list of sample point types`,
  //       });
  //     });
  // }

  // function fetchEquipments() {
  //   getEquipments()
  //     .then(({ result }) => {
  //       const mLists = [];
  //       result.data &&
  //         result.data.length > 0 &&
  //         result.data.forEach((res) =>
  //           mLists.push({
  //             equipmentId: res.equipmentId,
  //             equipmentName: res.equipmentName,
  //             name: res.equipmentName,
  //             id: res.equipmentId,
  //             text: res.equipmentName,
  //           })
  //         );
  //       setEquipments(mLists);
  //     })
  //     .catch((err) => {
  //       return openNotification({
  //         type: 'error',
  //         title: 'Failed to get sample point types',
  //         message: `We encountered an error while trying to get list of sample point types`,
  //       });
  //     });
  // }

  function getIconSet() {
    getIcons()
      .then(({ result }) => {
        const iconLists = [];
        result.data &&
          result.data.length > 0 &&
          result.data.forEach((res) =>
            iconLists.push({
              iconId: res.iconId,
              alt: res.iconName,
              iconName: res.iconName,
              iconSrc: res.iconSrc,
              id: res.iconId,
              text: res.iconName,
            })
          );
        setIconSet(iconLists);
      })
      .catch((err) => {
        return openNotification({
          type: 'error',
          title: 'Failed to load icons',
          message: `We encountered an error while trying to load the icon set`,
        });
      });
  }

  function clearFormFields() {
    formRef.current.setFieldsValue({ icon: '' });
    formRef.current.setFieldsValue({ runs });
    formRef.current.setFieldsValue({ samplePointName: '' });
    formRef.current.setFieldsValue({ samplePointType: '' });
    formRef.current.setFieldsValue({ latitude: '' });
    formRef.current.setFieldsValue({ longitude: '' });
    formRef.current.setFieldsValue({ equipment: '' });
    setIcon('');
    setLatitude('');
    setLongitude('');
    setMeasureNoice(initMeasureNoise);
  }

  useEffect(() => {
    // getSamplePointTypes();
    // fetchEquipments();
    getIconSet();
    if (editing && editingState && editingState[0]) {
      clearFormFields();
      const chord = editingState[0].actualCoordinates;
      const theLat = !chord ? '' : String(chord.split('-')[0]).trim();
      const theLng = !chord ? '' : String(chord.split('-')[1]).trim();
      const iconSr = editingState[0].icon.iconSrc
        ? editingState[0].icon.iconSrc
        : editingState[0].icon
        ? editingState[0].icon
        : '';
      setIcon({ iconSrc: iconSr });

      formRef.current.setFieldsValue({
        icon: iconSr,
      });

      formRef.current.setFieldsValue({ runs: editingState[0].checkCount });
      formRef.current.setFieldsValue({ samplePointName: editingState[0].samplePointName });
      formRef.current.setFieldsValue({ samplePointType: editingState[0].samplePointType });
      // formRef.current.setFieldsValue({
      //   latitude: { loc: theLat },
      // });
      // formRef.current.setFieldsValue({
      //   longitude: { loc: theLng },
      // });
      setLatitude(theLat);
      setLongitude(theLng);
      formRef.current.setFieldsValue({
        latitude: theLat,
      });
      formRef.current.setFieldsValue({
        longitude: theLng,
      });
      reload(true);
    }
    return;
  }, [editing, load]);

  const onFinish = () => {
    const GOTONEXT = 2;
    const theSamplePoints = [...samplePoints];
    //if (editing && editingState && editingState[0])
    //theSamplePoints.push(editingState[0]);

    try {
      const stepTwoPayload = {
        icon: icon ? { iconSrc: icon.iconSrc, iconName: (icon.iconName = '') } : {},
        runs,
        samplePointName: samplePointName ? properCase(samplePointName) : '',
        samplePointTypes,
        latitude: latitude && latitude.loc ? latitude.loc : latitude,
        longitude: longitude && longitude.loc ? longitude.loc : longitude,
        samplePoints: theSamplePoints,
        measureNoise: measureNoise,
        equipmentId: theSamplePoints.equipmentId,
      };

      console.log('step-two-payload', stepTwoPayload);
      updateMasterForm(stepTwoPayload, 'stepTwoState', GOTONEXT);
    } catch (e) {
      // console.log('Something went wrong', e);
    }
  };

  const onFinishFailed = () => {};

  const saveAndPrev = () => {
    const GOTOPREV = 0;
    const theSamplePoints = [...samplePoints];
    if (editing && editingState && editingState[0]) theSamplePoints.push(editingState[0]);
    try {
      const stepTwoPayload = {
        icon: icon ? { iconSrc: icon.iconSrc, iconName: (icon.iconName = '') } : {},
        runs,
        samplePointName: samplePointName ? properCase(samplePointName) : '',
        samplePointTypes: samplePointTypes,
        latitude: latitude && latitude.loc ? latitude.loc : latitude,
        longitude: longitude && longitude.loc ? longitude.loc : longitude,
        samplePoints: theSamplePoints,
        measureNoise: theSamplePoints.measureNoise,
        equipmentId: theSamplePoints.equipmentId,
      };
      updateMasterForm(stepTwoPayload, 'stepTwoState', GOTOPREV);
    } catch (e) {
      // console.log('Something went wrong', e);
    }
  };

  // const addSamplePoint2 = (record) => {
  //   try {
  //     // append to samplePoints
  //     // Reset form
  //     const currSamplePt = [...samplePoints];
  //     if (
  //       currSamplePt.some(
  //         (spPoint) =>
  //           spPoint.samplePointName.trim().toLowerCase() ===
  //           record.samplePointName.trim().toLowerCase()
  //       )
  //     ) {
  //       message.error(`Failed to ${editing ? 'update' : 'add'} record. SamplePoint name exists`);
  //       return;
  //     }

  //     const equipmentName = equipments.filter((x) => x.equipmentId === record.equipmentId)[0]
  //       .equipmentName;

  //     const newSamplePt = {
  //       key: currSamplePt.length,
  //       id: currSamplePt.length,
  //       icon: record.icon.iconSrc
  //         ? record.icon.iconSrc
  //         : record.icon
  //         ? record.icon
  //         : icon.iconSrc
  //         ? icon.iconSrc
  //         : '',
  //       checkCount: record.runs || 3,
  //       samplePointName: properCase(record.samplePointName),
  //       samplePointType: record.samplePointType,
  //       actualCoordinates: latitude && longitude ? `${latitude}-${longitude}` : '',
  //       coordinates: latitude && longitude ? convertDMS(latitude, longitude) : '',
  //       equipmentId: record.equipmentId,
  //       equipmentName: equipmentName,
  //       measureNoise: measureNoise,

  //       // actualCoordinates:
  //       //   record.latitude && record.longitude && record.latitude.loc && record.longitude.loc
  //       //     ? `${record.latitude.loc} - ${record.longitude.loc}`
  //       //     : record.latitude && record.longitude
  //       //     ? record.latitude
  //       //     : '',
  //       // coordinates:
  //       //   record.latitude && record.longitude && record.latitude.loc && record.longitude.loc
  //       //     ? convertDMS(record.latitude.loc, record.longitude.loc)
  //       //     : record.latitude && record.longitude
  //       //     ? convertDMS(record.latitude, record.longitude)
  //       //     : '',
  //       // record.latitude && record.longitude && record.latitude.loc && record.longitude.loc
  //       //   ? `{${toDegreesMinutesAndSeconds(record.latitude.loc)},
  //       //         ${toDegreesMinutesAndSeconds(record.longitude.loc)}}`
  //       //   : '',
  //     };

  //     if (editing && editingState && editingState[0]) {
  //       newSamplePt.id = editingState[0].id;
  //       newSamplePt.key = editingState[0].id;
  //     }

  //     currSamplePt.push(newSamplePt);

  //     setEditingState('');
  //     // reset form
  //     // setSamplePoints([]);

  //     formRef.current.setFieldsValue({ icon: '' });
  //     formRef.current.setFieldsValue({ runs: runs });
  //     formRef.current.setFieldsValue({ samplePointName: '' });
  //     formRef.current.setFieldsValue({ samplePointType: '' });
  //     setIcon({});

  //     message.success(`Sample point ${editing ? 'updated' : 'added'}`);
  //     setSamplePoints(currSamplePt);
  //     setEditing(false);
  //   } catch (err) {}
  // };

  // const displayOptions = (list) => {
  //   let key = list;
  //   let { [key]: name } = { samplePointTypes };

  //   if (name.length > 0) {
  //     return name.map((item) => {
  //       return (
  //         <Option value={item.id} key={item.id}>
  //           {item.text}
  //         </Option>
  //       );
  //     });
  //   }
  // };

  function Imgs({ src, alt = 'sample point icon' }) {
    return (
      <span className="icon-logo">
        <img src={src} style={{ verticalAlign: 'sub' }} alt={alt} />
      </span>
    );
  }

  const getSamplePointName = () => {
    let sptName = formRef.current.getFieldValue('samplePointName');
    return sptName;
  };

  const getCoordinateForMap = (whichCoordinate) => {
    let theCoordinate = formRef.current.getFieldValue(whichCoordinate);
    let key = whichCoordinate;
    let { [key]: stateCoordinate } = { latitude, longitude };

    return theCoordinate && theCoordinate.loc ? theCoordinate.loc : stateCoordinate;
  };

  const initValues = {
    // ['icon']: ['a'],
    runs,
    samplePointName,
    latitude,
    longitude,
  };

  const handleChangeSpType = (key) => {
    // if (key === spTypes[0]) {
    //   formRef.current.setFieldsValue({ samplePointName: '' });
    // } else {
    //   formRef.current.setFieldsValue({ standardSamplePointId: '' });
    // }

    setSelectedSpType(key);
  };

  return (
    <>
      <Form
        className={`${className} step-form`}
        layout="vertical"
        form={form}
        ref={formRef}
        //initialValues={initValues}
        initialValues={{
          standardSamplePointId: '',
          samplePointName: '',
          equipmentId: '',
          runs: 1,
          icon: '',
          latitude: '',
          longitude: '',
        }}
        onFinish={addSamplePoint}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={10}>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Form.Item label={'Choose Icon'} name="icon">
              {showIconSet && (
                <Modal
                  title={false}
                  visible={showIconSet}
                  className="icon-choose-modal"
                  onCancel={() => setShowIconSet(!showIconSet)}
                  maskClosable={false}
                  closable
                  footer={false}
                >
                  {!iconSets && <LoadingContent modal={true} />}
                  {iconSets && (
                    <Radio.Group
                      style={{ display: 'block', marginLeft: '-1.5rem', marginBottom: '5px' }}
                      onChange={(ev) => {
                        formRef.current.setFieldsValue({
                          icon: { iconSrc: ev.target.value },
                        });
                        setShowIconSet(!showIconSet);
                        setIcon({ iconSrc: ev.target.value });
                      }}
                      buttonStyle="solid"
                    >
                      <Row>
                        {iconSets.map((icn, index) => (
                          <Col span={2} key={icn.iconId || index}>
                            <Radio.Button value={icn.iconSrc} style={{ lineHeight: '32px' }}>
                              {<Imgs src={icn.iconSrc} alt={icn.iconName || ''} />}
                            </Radio.Button>
                          </Col>
                        ))}
                      </Row>
                    </Radio.Group>
                  )}
                </Modal>
              )}
              <>
                <SettingOutlined
                  style={{ fontSize: '2em' }}
                  onClick={() => setShowIconSet(!showIconSet)}
                />
                <br />
                {icon && icon.iconSrc && (
                  <span>
                    <img src={icon.iconSrc} alt={icon.iconName || 'sample point icon'} />
                  </span>
                )}
              </>
            </Form.Item>
          </Col>

          <Col lg={24} md={24} sm={24} xs={24}>
            <Box sx={{ display: 'flex', justifyContent: 'end', marginBottom: '-25px' }}>
              <Segmented value={selectedSpType} onChange={handleChangeSpType} options={spTypes} />
            </Box>

            {selectedSpType === spTypes[0] && (
              <Form.Item
                name="standardSamplePointId"
                label="Sample point"
                rules={[{ required: true, message: 'Standard sample point is required' }]}
                style={{ marginBottom: 0, display: 'block' }}
              >
                <Select
                  className="form-controlxx"
                  placeholder="Select sample point"
                  options={standardSamplePointOptions}
                />
              </Form.Item>
            )}

            {selectedSpType === spTypes[1] && (
              <Form.Item
                name="samplePointName"
                label="Sample Point"
                rules={[{ required: true, message: 'Sample Point is required' }]}
                style={{ marginBottom: 0, display: 'block' }}
              >
                <Input
                  placeholder="Enter sample point name"
                  className="form-controlx"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            )}
          </Col>

          <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item
              name="measurementId"
              label="Measurement Type"
              rules={[{ required: true, message: 'Sample Point Type is required!' }]}
              style={{ marginBottom: 0, display: 'block' }}
            >
              <Select
                placeholder="Select measurement type"
                className="form-controlxx"
                options={measurementOptions}
              />
            </Form.Item>
          </Col>

          <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item
              name="equipmentId"
              label="Equipement"
              style={{ marginBottom: 0, display: 'block' }}
            >
              <Select
                placeholder="Select Equipment"
                className="form-controlxx"
                options={equipmentOptions}
              />
            </Form.Item>
          </Col>

          {/* <Col lg={{ span: 6 }} md={{ span: 6 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item
              label="No. of Runs"
              name="runs"
            >
              <InputNumber
                min={1}
                disabled
                type="number"
                max={1}
                step={1}
                style={{ border: '1px solid gray', width: '100%' }}
                parser={(value) => parseInt(value)}
                size="small"
                className="form-controlx"
              />
            </Form.Item>
          </Col>
          <Col lg={{ span: 6 }} md={{ span: 6 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                // border: '1px solid lightgray',
                // padding: '2rem 0',
                marginTop: '2rem',
                height: '40px',
                padding: '0 0.2rem',
              }}
            >
              <span style={{ marginRight: '10px' }}>Measure Noise</span>
              <Switch
                checkedChildren={<span>Yes</span>}
                unCheckedChildren={<span>No</span>}
                //defaultChecked={measureNoise === 1 ? true : false}
                checked={measureNoise}
                onChange={(noiseValue) => {
                  setMeasureNoice(noiseValue);
                }}
              />
            </div>
          </Col> */}

          <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item
              name="latitude"
              label="Latitude"
              rules={[{ validator: checkLatitude }]}
              style={{ width: '100%' }}
            >
              <Input placeholder="Latitude" className="form-controlx" style={{ width: '100%' }} />
              {/* <LocationInput
                type="latitude"
                placeholder="Enter Latitude"
                value={{ latitude }}
                location={latitude}
                setLocation={setLatitude}
                className="form-controlx"
              /> */}
            </Form.Item>
          </Col>

          <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item
              name="longitude"
              label="Longitude"
              rules={[{ validator: checkLongitude }]}
              // style={{ display: 'inline-block', width: 'calc(100% - 20px)' }}
            >
              <Input placeholder="Longitude" className="form-controlx" style={{ width: '100%' }} />
              {/* <LocationInput
                type="longitude"
                placeholder="Enter Longitude"
                value={{ longitude }}
                location={longitude}
                setLocation={setLongitude}
                className="form-controlx"
              /> */}
            </Form.Item>
          </Col>
          <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            {latitude && longitude && isLatitude(latitude) && isLongitude(longitude) && (
              <div
                style={{
                  // marginTop: '0.1rem',
                  // transform: 'translateY(-0.5rem)',
                  // position: 'relative',
                  color: '#0c5bab',
                  cursor: 'pointer',
                  // textAlign: 'end',
                }}
                onClick={() => setShowMap(!showMap)}
              >
                {showMap ? 'Hide map' : 'Show on map'}
              </div>
            )}
          </Col>

          <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            <div>
              {showMap && (
                <Map
                  latitude={getCoordinateForMap('latitude')}
                  longitude={getCoordinateForMap('longitude')}
                  title={getSamplePointName()}
                  zoom={14}
                />
              )}
            </div>
          </Col>

          <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item
              label=" "
              // {...tailLayout}
              style={{ marginBottom: '0', display: 'block' }}
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.samplePointName.toLowerCase() !==
                  currentValues.samplePointName.toLowerCase() ||
                prevValues.samplePointType !== currentValues.samplePointType
              }
              // className="add-sample-point-btn"
            >
              {({ getFieldValue }) => {
                return (
                  <Button
                    type="primary"
                    appearance="default"
                    htmlType="submit"
                    // disabled={
                    //   !getFieldValue('samplePointName') || !getFieldValue('samplePointType')
                    // }
                  >
                    {/* {editing ? 'Update Sample Point' : 'Add Sample Point'} */}
                    {toEdit ? 'Update Sample Point' : 'Add Sample Point'}
                  </Button>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <br />

      <Form
        className={`${className} step-form step-form-table spt-data`}
        // {...formItemLayout}
        layout="horizontal"
      >
        <Row gutter={10}>
          <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            <Table
              columns={columns}
              dataSource={samplePoints}
              pagination={false}
              //scroll={{ y: 240 }}
            />
          </Col>
        </Row>
      </Form>

      <Form
        className={`${className} step-form`}
        // {...formItemLayout}
        layout="horizontal"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          // {...tailLayout}
          style={{ marginTop: '1rem', marginBottom: '2em', display: 'block' }}
        >
          <Button type="secondary" onClick={saveAndPrev}>
            Back
          </Button>
          <Button
            type="primary"
            appearance="default"
            htmlType="submit"
            style={{ margin: 8 }}
            disabled={samplePoints.length === 0}
          >
            Next
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default styled(StepTwo)`
  min-width: 735px !important;
  max-width: 90% !important;
  max-height: max-content;
  overflow-y: hidden;
  border-radius: 5px;

  .ant-modal-header {
    padding: 27px 40px 29px !important;
  }

  .ant-modal-body {
    padding: 27px 40px !important;
    max-height: 700px;
    overflow-y: auto;
  }

  .ant-modal-body,
  .ant-collapse {
    background-color: #fff;
  }
  label {
    font-family: var(--font-family);
    font-size: var(--form-label-fs);
    font-weight: var(--form-label-fw);
    margin-bottom: var(--form-label-mb);
    letter-spacing: normal;
    color: var(--dark);
  }
  .pl-3 {
    padding-left: 19px;
  }
  .ant-select-selection {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  .ant-select-selection--single {
    height: 41px;
  }
  .ant-select-selection__rendered {
    margin-top: 3px;
  }
  .ant-form-item-label {
    text-align: left;
  }
  .form-control {
    width: 100%;
    height: 41px;
    padding: 0.8rem 1rem;
    border-radius: 5px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-color: rgba(245, 247, 255, 0.2);
    &:focus {
      outline: 0;
      border: 0.5px solid #007ace;
    }
  }
`;
