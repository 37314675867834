import React from 'react';
import PageContainer from '../components/common/PageContainer';
import Layout from '../components/common/Layout';
import UpdateFacilityScreen from '../components/screens/facilities2/UpdateFacilityScreen';

export default function FacilityUpdate(props) {
  return (
    <Layout headerTitle="Edit Facility">
      <PageContainer id="update_facility">
        <UpdateFacilityScreen props={props} />
      </PageContainer>
    </Layout>
  );
}
