import { Close, DateRange, LockOpen, Lock } from '@mui/icons-material';
import { Box, Dialog, Grid2, IconButton, Paper, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { generateFacilityReport } from '../../../../helpers/api';
import LoaderSkeleton from '../../../common/LoaderSkeleton';
import { Empty, Popconfirm } from 'antd';
import FacilityReportQuaterLock from './FacilityReportQuaterLock';

export default function FacilityReportQuarters({ open, onClose, record }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dialogLock, setDialogLock] = useState(false);
  const [row, setRow] = useState();
  const [refetch, setRefetch] = useState(false);

  console.log(record);
  console.log(data);

  const fetchData = () => {
    setLoading(true);
    generateFacilityReport(record?.facilityId, new Date().getFullYear()?.toString())
      .then((response) => {
        //console.log(response);
        const responseData = response?.result?.data;
        setData(responseData);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
        setRefetch(false);
      });
  };

  const handleRendering = () => {
    if (loading) {
      return (
        <Box>
          <LoaderSkeleton />
        </Box>
      );
    }

    if (!loading && !data) {
      return (
        <Box>
          <Empty />
        </Box>
      );
    }

    if (!loading && data) {
      return renderContent();
    }
  };

  const renderContent = () => {
    return (
      <Box>
        <Box display={'flex'} alignItems={'center'}>
          <Stack direction={'column'} sx={{ padding: '0 2rem', flexGrow: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }} color="primary">
              {record?.facilityName}
            </Typography>
            <Typography variant="body2" color="textDisabled">
              Select quarter to reserve for QAQC recording
            </Typography>
          </Stack>
          <Typography sx={{ padding: '0 2rem' }}>{new Date().getFullYear()}</Typography>
        </Box>
        <Grid2 container spacing={2} sx={{ padding: '2rem' }}>
          {data?.map((item) => (
            <Grid2 key={item?.reportId} size={{ lg: 3, md: 3, sm: 2, xs: 2 }}>
              <Paper
                onClick={() => {
                  if (item?.reportType !== 'QAQC') {
                    setDialogLock(true);
                    setRow(item);
                  }
                }}
                sx={{
                  padding: '1rem',
                  background: item?.reportType === 'QAQC' ? null : '#d9d9d9',
                  borderWidth: item?.reportType === 'QAQC' ? 1 : 0,
                  borderStyle: item?.reportType === 'QAQC' ? 'solid' : 'none',
                  borderColor: item?.reportType === 'QAQC' ? 'primary.main' : 'none',
                  '&:hover': {
                    background: '#f5f7ff',
                    cursor: item?.reportType === 'QAQC' ? 'pointer' : 'normal',
                    opacity: 0.8,
                  },
                }}
              >
                <Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {item?.reportType === 'QAQC' ? (
                      <Typography variant="caption" color="info" flexGrow={1}>
                        Reserved for QAQC
                      </Typography>
                    ) : (
                      <Typography variant="caption" color="error" flexGrow={1}>
                        Not for QAQC
                      </Typography>
                    )}

                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                      {item?.reportType === 'QAQC' ? (
                        <LockOpen color="primary" />
                      ) : (
                        <Lock color="error" />
                      )}
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <DateRange sx={{ fontSize: '100px' }} color="disabled" />
                  </Box>
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="body1">{item?.reportTitle}</Typography>
                    <Typography variant="body2" color="textDisabled">
                      {item?.remarks}
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Grid2>
          ))}
        </Grid2>
      </Box>
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (refetch) {
      fetchData();
    }
  }, [refetch]);

  return (
    <Box>
      <Dialog open={open} fullWidth maxWidth="lg">
        <Box display="flex" alignItems={'center'}>
          <Typography flexGrow={1}></Typography>
          <Box padding="1rem">
            <IconButton onClick={() => onClose()}>
              <Close />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ padding: '1rem' }}>{handleRendering()}</Box>
      </Dialog>

      <FacilityReportQuaterLock
        open={dialogLock}
        onClose={() => setDialogLock(false)}
        report={row}
        onRefetch={() => setRefetch(true)}
      />
    </Box>
  );
}
