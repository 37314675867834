import React from 'react';
import PageContainer from '../components/common/PageContainer';
import Layout from '../components/common/Layout';
import CreateFacilityScreen from '../components/screens/facilities2/CreateFacilityScreen';

export default function FacilityCreate(props) {
  return (
    <Layout headerTitle="New Facility">
      <PageContainer id="new_facility">
        <CreateFacilityScreen props={props} />
      </PageContainer>
    </Layout>
  );
}
