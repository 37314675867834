import React, { useState, useEffect } from 'react';
import Layout from '../../../../../components/common/Layout';
import PageHeader from '../../../../../components/common/PageHeader';
import PageContent from '../../../../../components/common/PageContent';
import Table from '../../../../../components/common/Table';
import Button from '../../../../../components/common/Button';
import { Link } from 'react-router-dom';
import SearchInput from '../../../../../components/common/SearchInput';
import LoadingContent from '../../../../../components/common/LoadingContent';
import { Row, Col, Popconfirm, Breadcrumb } from 'antd';
import { PlusOutlined, EditOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { getSectors, deleteSector } from '../../../../../helpers/api';
import { openNotification } from '../../../../../helpers/notification';
import AddSectorModal from './AddSector';
import EditSectorModal from './EditSector';
import './style.css';

const Sector = (props) => {
  //console.log(props);
  const [loading, setLoading] = useState(false);
  const [sectorsList, setSectorsList] = useState(null);
  const [searchResults, setSearchResults] = useState(null);
  const [showSectorModal, setShowSectorModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedSectorId, setSelectedSectorId] = useState('');
  const [selectedSectorName, setSelectedSectorName] = useState('');
  const [reload, setReload] = useState(false);

  const { userCategory, userPermission } = props;

  //const userCat = userCategory.trim().toLowerCase();
  const userPerm =
    userPermission && userPermission.length > 0
      ? userPermission.map((mp) => mp.trim().toLowerCase())
      : [];

  console.log(userPerm);
  const canCreateSector = userPerm.includes('create_sector');

  useEffect(() => {
    refreshSectors();
  }, []);

  useEffect(() => {
    if (reload) {
      refreshSectors();
    }
  }, [reload]);

  // GET LIST OF SECTORS
  const refreshSectors = () => {
    setLoading(true);
    getSectors()
      .then((res) => {
        setSectorsList(res.result.data);
        setLoading(false);
        setReload(false);
      })
      .catch((error) => {
        setLoading(false);
        return openNotification({
          type: 'error',
          title: 'Something went wrong fetching sectors',
          message: error,
        });
      });
  };
  const closeSectorModal = () => {
    setShowSectorModal(false);
  };
  const closeEditModal = () => {
    setShowEditModal(false);
  };
  const getSelectedSectorData = (sectorId) => {
    const filteredData = sectorsList.filter((sector) => sector.sectorId === sectorId);
    console.log(filteredData);
    if (filteredData && filteredData.length > 0) {
      setSelectedSectorName(filteredData[0].sectorName);
    }
  };
  const editSector = (ev, id) => {
    console.log(id, 'edit id');
    getSelectedSectorData(id);
    setSelectedSectorId(id);
    setShowEditModal(true);
  };

  const checkSectorStatus = (ev, id) => {
    setLoading(true);
    const filterSector = sectorsList.filter((sector) => sector.sectorId === id);

    if (filterSector) {
      console.log(filterSector[0].facilityCount, 'sector');
      if (filterSector[0].facilityCount > 0) {
        setLoading(false);
        return openNotification({
          type: 'error',
          title: 'Error deleting sector',
          message: 'Sector already contains facilities',
        });
      } else {
        removeSector(id);
      }
    }
  };

  const removeSector = (id) => {
    deleteSector(id)
      .then((res) => {
        console.log(res, 'response');
        setReload(true);
        setLoading(false);
        return openNotification({
          type: 'success',
          title: 'Sector deleted successfully!',
        });
      })
      .catch((error) => {
        console.log(error, 'error');
        setLoading(false);
        return openNotification({
          type: 'error',
          title: 'Something went wrong',
          message: error,
        });
      });
  };

  const columns = [
    {
      title: 'Sector Name',
      dataIndex: 'sectorName',
      key: 'sectorName',
      render: (sectorName) => (
        <>
          <span>{sectorName.toUpperCase()}</span>
        </>
      ),
    },
    {
      title: 'No of Facilities',
      dataIndex: 'facilityCount',
      key: '',
      render: (facilityCount) => (
        <>
          <span>
            {facilityCount === 0 ? (
              'No Facilities'
            ) : facilityCount === 1 ? (
              <>{facilityCount} Facility </>
            ) : (
              <>{facilityCount} facilities</>
            )}
          </span>
        </>
      ),
    },

    {
      title: '',
      dataIndex: 'sectorId',
      key: 'sectorId',
      render: (sectorId) => (
        <>
          <Button
            type="secondary"
            className="edit__sector__btn"
            id={sectorId}
            onClick={(e) => editSector(e, sectorId)}
          >
            <EditOutlined />
            Edit
          </Button>
          <Popconfirm
            title="Are you sure delete this sector?"
            onConfirm={(e) => checkSectorStatus(e, sectorId)}
            // onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button className="text-danger transparent__bg" loading={loading} disabled={loading}>
              <CloseCircleOutlined /> Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  const reloadData = () => {
    setReload(true);
  };
  const handleSearch = (ev) => {
    searchSectors(ev);
  };
  const searchSectors = (searchTerm) => {
    const url = `/Sector?search=${searchTerm}`;

    getSectors(url)
      .then((res) => {
        console.log(res.result.data);
        setSearchResults(res.result.data);
      })
      .catch((error) => {
        console.log(error);
        return openNotification({
          type: 'error',
          title: 'Something went wrong',
          message: error,
        });
      });
  };

  const keyedData = sectorsList;
  keyedData && keyedData.length && keyedData.forEach((dt) => (dt['key'] = dt.sectorName));

  return (
    <Layout headerTitle="Sector Management">
      <div className="breadcrumb__container ml-2rem mt-1rem">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/settings">Settings</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/settings/lookups">Lookups</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>State Lookup</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      {showSectorModal && (
        <AddSectorModal
          open={showSectorModal}
          close={closeSectorModal}
          reloadSectors={reloadData}
        />
      )}
      {showEditModal && (
        <EditSectorModal
          open={showEditModal}
          close={closeEditModal}
          reloadSectors={reloadData}
          sectorId={selectedSectorId}
          sectorName={selectedSectorName}
        />
      )}

      {/* <PageHeader split={true} className="no-padding-x">
        <Row
          // gutter={{ xs: 4, sm: 8, md: 16, lg: 22 }}
          type="flex"
          align="start"
          className="pl-2rem"
          style={{ width: '100%' }}
        >
          <Col span={8} style={{ flexFlow: 'column' }}>
            <h3 className="heading mb-20" style={{ alignSelf: 'flex-start' }}>
              Manage Sector
            </h3>
            <p style={{ alignSelf: 'flex-start' }}>
              Sector lookups define sectors for different facilities
            </p>
          </Col>
          <Col
            span={8}
            className="text-right"
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button
              appearance="default"
              type="primary"
              className="add__sector__button"
              onClick={() => setShowSectorModal(true)}
            >
              <PlusOutlined />
              Add New Sector
            </Button>
          </Col>
        </Row>
      </PageHeader> */}
      <PageHeader split={true} className="no-padding-x">
        <Row
          // gutter={{ xs: 4, sm: 8, md: 16, lg: 22 }}
          type="flex"
          align="start"
          justify="space-between"
          className="pl-2rem header-span100"
          style={{ width: '100%' }}
        >
          <Col span={16} style={{ flexFlow: 'column' }}>
            <Row className="mb-1rem">
              <Col span={20} style={{ flexFlow: 'column' }}>
                <h3 className="heading mb-20" style={{ alignSelf: 'flex-start' }}>
                  Manage Sector
                </h3>
                <p style={{ alignSelf: 'flex-start' }}>
                  Sector lookups define sectors for different facilities
                </p>
                <p style={{ alignSelf: 'flex-start' }}>
                  You can edit, delete and create new sectors.
                </p>
              </Col>
            </Row>
            <Row
              type="flex"
              align="start"
              justify="space-between"
              // style={{ width: '100%' }}
            >
              <Col span={6} className="text-left">
                {canCreateSector && (
                  <Button
                    appearance="default"
                    type="primary"
                    style={{ marginLeft: '0px !important' }}
                    nomargin
                    className="add__sector__button"
                    onClick={() => setShowSectorModal(true)}
                  >
                    <PlusOutlined />
                    Add New Sector
                  </Button>
                )}
              </Col>
              <Col lg={8}>
                <Row type="flex" justify="end">
                  <Col lg={18}>
                    <SearchInput placeholder="Search sectors" searchTable={handleSearch} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </PageHeader>

      <PageContent>
        <Row>
          <Col md={20} lg={{ span: 16 }}>
            <div className="lga__lookup">
              {loading ? (
                <LoadingContent pageLoading={true} />
              ) : (
                <div>
                  <Table
                    dataSource={searchResults !== null ? searchResults : keyedData}
                    columns={columns}
                    pagination={true}
                    rowClassName="sector__lookup__row"
                    paddedTable={true}
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </PageContent>
    </Layout>
  );
};

export default Sector;
