import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  facilityName: '',
  stateId: '',
  address: '',
  status: 'ACTIVE',
  facilityEia: 1,
  facilityPersonel: [],
  coordinates: {
    latitude: '',
    longitude: '',
  },
  remarks: '',
  sectorId: '',
  zoneId: '',
  lgaId: '',
  consultantId: [],
  productId: [],
  productName: [],
  rawMaterialId: [],
  rawMaterialName: [],
  wasteId: [],
  wasteName: [],
  samplePoints: [],
  eiaCert: '',
  facilityCategoryId: '',
  registrationNo: '',
};

const facilityUpdateSlice = createSlice({
  name: 'facilityUpdateSlice',
  initialState,
  reducers: {
    onStoreUpdateFacilityDetails: (state, action) => {
      return {
        ...state,

        //Step one
        facilityName: action.payload?.facilityName,
        registrationNo: action.payload?.registrationNo,
        address: action.payload?.address,
        coordinates: action.payload?.coordinates,
        facilityCategoryId: action.payload?.facilityCategoryId,
        sectorId: action.payload?.sectorId,
        productId: action.payload?.productId,
        productName: action.payload?.productName,
        rawMaterialId: action.payload?.rawMaterialId,
        rawMaterialName: action.payload?.rawMaterialName,
        wasteId: action.payload?.wasteId,
        wasteName: action.payload?.wasteName,
        zoneId: action.payload?.zoneId,
        stateId: action.payload?.stateId,
        lgaId: action.payload?.lgaId,
        status: action.payload?.status,
        facilityEia: action.payload?.facilityEia,
        eiaCert: action.payload?.eiaCert,
        remarks: action.payload?.remarks,

        //step 2
        samplePoints: action.payload?.samplePoints,

        //step 3
        consultantId: action.payload?.consultantId,
        facilityPersonel: action.payload?.facilityPersonel,
      };
    },
    onClearUpdateFacilityDetails: () => {
      return {
        facilityName: '',
        stateId: '',
        address: '',
        status: 'ACTIVE',
        facilityEia: 1,
        facilityPersonel: [],
        coordinates: undefined,
        remarks: '',
        sectorId: '',
        zoneId: '',
        lgaId: '',
        consultantId: [],
        productId: [],
        productName: [],
        rawMaterialId: [],
        rawMaterialName: [],
        wasteId: [],
        wasteName: [],
        samplePoints: [],
        eiaCert: '',
        facilityCategoryId: '',
        registrationNo: '',
      };
    },
  },
});

export const {
  onStoreUpdateFacilityDetails,
  onClearUpdateFacilityDetails,
} = facilityUpdateSlice.actions;
export default facilityUpdateSlice.reducer;
