import React, { useContext, useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AppContext } from '../store/AppContext';

const permit = [];
const CategoryAccess = (props) => {
  const [permissions, setPermissions] = useState([]);
  const { state } = useContext(AppContext);
  const authUserId = state.auth.result.data.userId;
  //console.log("appstate", state.auth.result.data.userId)
  const { userPermission } = state.auth.result.data;

  const { userCategory, component: Component, ...rest } = props;
  const CATEGORY_LIST = {
    edm: 'EDM',
    regulator: 'REGULATOR',
    consultant: 'CONSULTANT',
  };

  useEffect(() => {
    const permite = [];
    if (userPermission && userPermission.length > 0) {
      userPermission.map((userPermit) => {
        if (userPermit.permissionActivity.length > 0) {
          return userPermit.permissionActivity.map((activity) => {
            permite.push(activity.privilegeCode);
          });
        }
      });
    }
    setPermissions(permite);
  }, []);

  const definedRoutes = {
    regulator: [
      '/',
      '/facilities',
      '/reports',
      //'/logs',
      '/reporting',
      '/analytics',
      '/settings',
      '/settings/profile',
      '/settings/configurations',
      '/404',
    ],

    consultant: [
      '/',
      '/facilities',
      '/reports',
      '/settings',
      '/settings/profile',
      '/settings/configurations',
      '/404',
    ],
  };

  const access = (props) => {
    if (userCategory && userCategory.trim() === CATEGORY_LIST.regulator) {
      // if (props.location.pathname === '/') {
      //   return (
      //     <Redirect
      //       to={{
      //         pathname: '/',
      //         // pathname: '/reports',
      //         state: { userPermission: permissions },
      //       }}
      //     />
      //   );
      // } else
      if (definedRoutes['regulator'].includes(props.location.pathname)) {
        return (
          <Component
            {...props}
            userCategory={userCategory}
            userPermission={permissions}
            userId={authUserId}
          />
        );
      } else return <Redirect to="/404" />;
    }
    if (userCategory && userCategory.trim() === CATEGORY_LIST.consultant) {
      if (props.location.pathname === '/') {
        return (
          <Redirect
            to={{
              pathname: '/facilities',
              state: { userPermission: permissions },
            }}
          />
        );
      } else if (definedRoutes['consultant'].includes(props.location.pathname)) {
        return (
          <Component
            {...props}
            userCategory={userCategory}
            userPermission={permissions}
            userId={authUserId}
          />
        );
      } else return <Redirect to="/404" />;
    }
    return (
      <Component
        {...props}
        userCategory={userCategory}
        userPermission={permissions}
        userId={authUserId}
      />
    );
  };

  return <Route {...rest} render={(props) => <>{access(props)}</>} />;
};
export default CategoryAccess;
